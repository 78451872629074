<template>
  <div class="contianer_detail">
    <clone-tab :bgcShow="bgcShow"></clone-tab>

    <div class="address_title">收件地址</div>

    <div class="conter">
      <div class="address_img">
        <img src="../../assets/Slice101.png" />
      </div>
      <div class="address_message">
        <div style="display: flex" class="phone">
          <div class="messageUser">{{dataList.receiver_name}}</div>
          <div>{{dataList.receiver_mobile}}</div>
        </div>
        <div>{{dataList.receiver_address}}</div>
      </div>
    </div>

    <div class="address_title">{{dataList.order_state_text}}</div>

    <div class="order_header">
      <div class="order_img">
        <img :src="dataList.goods_image" />
      </div>
      <div class="message_item">
        <div>{{dataList.goods_name}}</div>
        <div class="price_item_num">
          <div style="color:rgba(242, 99, 22, 1)">￥{{dataList.goods_price}}</div>
          <div>x{{dataList.goods_num}}</div>
        </div>
        <div class="price_box" v-if="dataList.order_type==1">享受会员专有价</div>
        <div class="price_box" v-else>享受股东专有价</div>
        <div class="pay">实付￥{{dataList.order_amount}}</div>
      </div>
    </div>

    <div class="text_goods">订单编号：{{dataList.order_sn}}</div>
    <div class="text_goods">支付金额：{{dataList.order_amount}}</div>
    <div class="text_goods">下单时间：{{dataList.create_time}}</div>
    <div class="text_goods">支付方式：微信支付</div>
    <div class="banList">
      <div class="confim" v-if="dataList.order_state==30" @click="delivery(dataList.order_id)">确认收货</div>
      <div class="confim" v-if="dataList.order_state==20" @click="cloneBtn(dataList.order_id)">取消订单</div>
      <div class="confim" v-if="dataList.order_state==0&&dataList.pay_info" @click="cloneBtn(dataList.order_id,1)">取消退款</div>
      <div class="confim" v-if="dataList.order_state==10" @click="buyOrderBtn(dataList.order_id)">立即支付</div>
      <div class="confim" v-if="dataList.order_state>30||dataList.order_state==0" @click="deleteBtn(dataList.order_id)">删除订单</div>
    </div>

  </div>
</template>
<script>
import cloneTab from "../../components/expenditure/cloneTab";
import { Toast } from "vant";
import * as apiCommon from "@/api/common";
import wx from "weixin-js-sdk";
import {
  orderDetail_api,
  cloneReturn_api,
  orderClone_api,
  orderDelete_api,
  bugOrder_api,
  deliveryDeatil_api
} from "@/api/deal";
export default {
  data() {
    return {
      bgcShow: true,
      dataList: null
    };
  },
  components: {
    cloneTab
  },
  created() {
    document.title = "订单详情";
    this.queryList = this.$route.query;
    this.getList();
  },
  methods: {
    getList() {
      orderDetail_api(this.queryList.id).then(res => {
        if (res.code == 0) {
          this.dataList = res.data;
            if (this.dataList.order_state == 0) {
              this.dataList.order_state_text = "取消";
            } else if (this.dataList.order_state == 10) {
              this.dataList.order_state_text = "待支付";
            } else if (this.dataList.order_state == 20) {
              this.dataList.order_state_text = "待发货";
            } else if(this.dataList.order_state==30){
              this.dataList.order_state_text = "待收货";
            }else if(this.dataList.order_state==40){
              this.dataList.order_state_text = "已收货";
            }
            if(this.dataList.refund_state == 10){
              this.dataList.order_state_text = "退款中";
            }else if(this.dataList.refund_state == 20){
              this.dataList.order_state_text = "退款已完成";
            }else if(this.dataList.refund_state ==30){
              this.dataList.order_state_text = "拒绝退款";
            }
        }
      });
    },
    cloneBtn(id, status) {
      if (status == 1) {
        let data = {
          type: "cancel"
        };
        cloneReturn_api(this.dataList.order_id, data).then(res => {
          if (res.code == 0) {
            this.$toast("操作成功");
            this.$router.go(-1);
          }else{
            this.$toast(res.error);
          }
        });
      } else {
        orderClone_api(id).then(res => {
          if (res.code == 0) {
            this.$toast("操作成功");
            this.$router.go(-1);
          }else{
            this.$toast(res.error);
          }
        });
      }
    },
    deleteBtn(id) {
      orderDelete_api(id).then(res => {
        if (res.code == 0) {
          this.$toast("操作成功");
          this.$router.go(-1);
        }
      });
    },
    buyOrderBtn(id) {
      bugOrder_api({ order_id: id }).then(res => {
        if (res.code == 0) {
          this.$toast("操作成功");
          this.wxPay(res)
        }
      });
    },
    async wxPay(res) {
      let that = this;
      Toast.loading({
        message: "加载中...",
        forbidClick: true
      });
      let res1 = await apiCommon.getConfig();
      console.log(res1);
      let tableData = res1.data;
      wx.config(tableData);
      wx.chooseWXPay({
        timestamp: res.data.pay_info.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: res.data.pay_info.nonceStr, // 支付签名随机串，不长于 32 位
        package: res.data.pay_info.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: "MD5", // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: res.data.pay_info.paySign, // 支付签名
        success(res) {
          if (res.errMsg == "chooseWXPay:ok") {
            that.$toast("支付成功");
            that.$router.go(-1)
          } else {
            alert(res.errMsg);
            that.$toast("支付失败", "middle");
          }
        },
        cancel: function(res) {
          that.$toast("支付取消", "middle", res);
        }
      });
    },
    delivery(id){
      deliveryDeatil_api({ order_id: id }).then(res => {
        if (res.code == 0) {
          this.$toast("操作成功");
          this.$router.go(-1);
        }
      });
    }
  }
};
</script>
<style>
.contianer_detail {
  background-color: #f2f2f2;
  width: 100%;
  height: 100vh;
  padding: 40px 15px 0;
}
.van-toast {
  width: 300px;
  min-height: 120px;
}
.van-toast__text {
  font-size: 35px;
}
.address_title {
  font-size: 36px;
  color: #2a2828;
  font-weight: bold;
  margin: 41px 0 25px 25px;
}
.address_goods {
  width: 750px;
  height: 1624px;
  background: #f2f2f2;
  border-radius: 0px 0px 0px 0px;
  margin: 30px auto;
}
.conter {
  width: 700px;
  height: 180px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  opacity: 1;
  border-radius: 5px;
  margin: 30px auto 25px;
  padding: 52px 25px 52px 30px;
  box-sizing: border-box;
  display: flex;
}
.address_img {
  width: 52px;
  height: 65px;
}
.address_img img {
  width: 100%;
  height: 100%;
}
.address_message {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 110px 0 30px;
}
.phone {
  margin-bottom: 20px;
}
.messageUser {
  font-size: 25px;
  color: rgba(0, 0, 0, 1);
  margin-right: 10px;
}
.container_order {
  width: 100%;
  height: 100vh;
  background-color: rgba(246, 246, 249, 1);
}

.order_header {
  display: flex;
  font-size: 25px;
  width: 720px;
  height: 340px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 10px 10px;
  opacity: 1;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 30px;
}
.order_img {
  width: 250px;
  height: 250px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 0px;
  margin-right: 28px;
}
.order_img img {
  width: 100%;
  height: 100%;
}
.message_item {
  width: 380px;
  margin-top: 25px;
}

.price_item_num {
  margin: 30px 0;
  display: flex;
  justify-content: space-between;
}
.price_item_num span {
  margin-right: 20px;
}
.price_box {
  width: 164px;
  height: 28px;
  background: rgba(242, 99, 22, 0.24);
  border-radius: 0px;
  font-size: 20px;
  color: #f26316;
  text-align: center;
  line-height: 28px;
}
.pay {
  margin: 40px 0 0 151px;
  font-size: 36px;
  color: #2a2828;
}
.text_goods {
  font-size: 30px;
  color: #1a1925;
  margin-bottom: 30px;
  font-weight: bold;
}
.confim {
  width: 304px;
  height: 97px;
  background: #2a2828;
  box-shadow: 0px 0px 10px 0px rgba(120, 120, 120, 0.15);
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  text-align: center;
  line-height: 97px;
  font-size: 35px;
  color: #ffffff;
  margin: 50px auto;
}

.banList {
  display: flex;
  justify-content: space-between;
}
</style>
